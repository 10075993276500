import { PromotionClicked, promotionClicked } from '@/rudder-typer';
import { PageSection } from '@/utils/analytics/PageSection';

export interface NutsPromotion extends Omit<PromotionClicked, 'page_section'> {
  readonly pageSection: PageSection<'Global Campaign Carousel'>;
}

export function sendPromotionClickedEvent({ pageSection, ...eventProps }: NutsPromotion) {
  promotionClicked({
    ...eventProps,
    page_section: pageSection,
  });
}
