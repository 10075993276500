import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import type { IsEqual } from 'type-fest/source/internal';

import { NutsLineItem } from '@/lib/cart/lineItem';
import {
  CartViewedProductsItem,
  CheckoutStartedProductsItem,
  OrderPlacedProductsItem,
  productsSearched,
} from '@/rudder-typer';
import { getUnitName } from '@/utils/product';

export interface GoogleEventObjItem {
  coupon?: string;
  index?: number;
  item_id: string;
  item_name?: string;
  item_variant?: string;
  price: number;
  quantity?: number;
}

export interface GoogleEventObj {
  items: GoogleEventObjItem[];
}

export type StandardProductsItem = CartViewedProductsItem;

// assert that various cart/checkout-related RS *ProductsItem types are identical
type StaticAssert<T extends true> = T;
type _check = StaticAssert<IsEqual<StandardProductsItem, CartViewedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, CheckoutStartedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, OrderPlacedProductsItem>>;

export const formatStandardProductsItem = (lineItem: NutsLineItem): StandardProductsItem => ({
  name: lineItem.name?.en,
  price: dollars(lineItem.piecePrice),
  product_id: lineItem.productKey,
  quantity: lineItem.quantity,
  reporting_category: lineItem.variant.attributes?.find((attr) => attr.name === 'reportingCategory')
    ?.value.key,
  sku: lineItem.variant.sku,
  variant: getUnitName(lineItem.variant.variantName) ?? undefined,
});

export const sendProductsSearchedEvent = (query: string): void => {
  if (query) productsSearched({ query });
};
