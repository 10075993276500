<script setup lang="ts">
import { from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { useIntersectionObserver } from '@vueuse/core';
import { computed, ref, useSlots, useTemplateRef } from 'vue';

import { reportEngagement } from '@/api/dynamic-yield/engagement';
import brokenPeanut from '@/assets/broken-peanut.png';
import RouteLink from '@/components/base/RouteLink.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import { BaseChoice } from '@/lib/personalization/dynamicYield';
import {
  ProductClickedPageSection,
  sendProductClickedEvent,
} from '@/utils/analytics/productClickedEvent';
import {
  isListPageSection,
  NutsViewItemListItem,
  pushProductListViewed,
} from '@/utils/analytics/productListViewedEvent';
import { ProductCardData } from '@/utils/productCard';
import { reportError } from '@/utils/reportError';

const props = withDefaults(
  defineProps<{
    analyticsList?: string;
    decisionId?: BaseChoice['decisionId'];
    imageSize?: 'small' | 'medium';
    impressionTrackable?: boolean;
    index?: number;
    linkToPDP?: boolean;
    pageSection?: ProductClickedPageSection;
    product: ProductCardData;
    textSize?: 'small' | 'base';
    trackingEventLocation?: string;
  }>(),
  {
    linkToPDP: true,
    textSize: 'base',
    trackingEventLocation: '',
  },
);

const slots = useSlots();
const fullCardClickable = computed(() => !slots.button && props.linkToPDP);

const reportClick = () => {
  if (props.decisionId && props.product.slotId) {
    reportEngagement(props.decisionId, props.product.slotId);
  }

  if (props.linkToPDP) {
    sendProductClickedEvent(props.product, props.pageSection, props.index);
  }
};

const itemListName = computed<string>(() => props.analyticsList || props.trackingEventLocation);
const nutsViewItemListItem = computed<NutsViewItemListItem>(() => ({
  cost: props.product.cost === undefined ? undefined : from(parseFloat(props.product.cost)),
  coupon: props.product.totalSavings?.description?.en,
  discount: props.product.totalSavings?.value || from(0),
  name: props.product.name,
  position: props.index ?? 0,
  price: props.product.piecePrice,
  priceBeforeDiscount: props.product.listPrice.value,
  product_id: props.product.productKey,
  quantity: 1,
  reporting_category: props.product.reportingCategory,
  searchQueryID: props.product.searchQueryId,
  sku: props.product.sku,
  variant: props.product.unitName,
  weight: props.product.weight,
}));

const horizontalProductCard = useTemplateRef('horizontalProductCard');
const impressionLogged = ref(false);
const reportImpression = () => {
  if (!props.impressionTrackable || impressionLogged.value) return;

  if (!isListPageSection(props.pageSection)) {
    reportError(`Incorrect page_section for Product List Viewed event: ${props.pageSection}`);
    return;
  }

  impressionLogged.value = true;
  pushProductListViewed(itemListName.value, nutsViewItemListItem.value, props.pageSection);
};

const textComponent = computed(() => (props.textSize === 'small' ? SmallBodyText : BaseBodyText));

useIntersectionObserver(horizontalProductCard, ([{ isIntersecting }]) => {
  if (isIntersecting) reportImpression();
});
</script>

<template>
  <div ref="horizontalProductCard">
    <component
      :is="fullCardClickable ? RouteLink : 'div'"
      :to="fullCardClickable ? product.path : undefined"
      class="relative flex items-center no-underline hover:no-underline"
      @click="fullCardClickable && reportClick()"
    >
      <slot name="badge" />
      <component
        :is="linkToPDP ? RouteLink : 'div'"
        :to="linkToPDP ? product.path : undefined"
        class="flex items-center justify-center no-underline hover:no-underline"
        @click="!fullCardClickable && reportClick()"
      >
        <div
          class="bg-white"
          :class="{
            'w-20 h-14': imageSize === 'small',
            'w-24 h-14 md:h-20 md:w-32': imageSize === 'medium',
          }"
        >
          <img
            v-if="product.imageUrl"
            :alt="product.name"
            class="object-contain w-full h-full"
            :src="product.imageUrl"
          />
          <img
            v-else
            alt="placeholder image"
            class="object-contain w-16 h-12 md:w-24 md:h-16 shrink-0"
            :src="brokenPeanut"
          />
        </div>
      </component>
      <div class="flex flex-col w-full ml-2">
        <component
          :is="linkToPDP ? RouteLink : 'div'"
          :to="linkToPDP ? product.path : undefined"
          class="no-underline hover:no-underline"
          @click="!fullCardClickable && reportClick()"
        >
          <component :is="textComponent" class="font-semibold text-black" data-test="product-title">
            <slot name="title">{{ product.name }}</slot>
          </component>
        </component>
        <div class="flex items-center mt-1">
          <slot name="price" />
          <div v-if="product.averageRating" class="flex items-center">
            <img
              aria-hidden="true"
              alt="star"
              class="object-contain w-4 h-4 mx-1 shrink-0"
              src="@/assets/pdp/star-full.svg"
            />
            <span class="sr-only" data-test="total-reviews">
              {{ product.averageRating.toFixed(1) }} out of 5 stars
              {{ product.totalReviews ? `and ${product.totalReviews} reviews` : '' }}
            </span>
            <component :is="textComponent" aria-hidden="true" class="text-neutral-500">
              ({{ product.averageRating.toFixed(1) }}) {{ product.totalReviews }}
            </component>
          </div>
        </div>
        <slot name="button" />
      </div>
    </component>
  </div>
</template>
