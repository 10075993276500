import { defineStore, storeToRefs } from 'pinia';

/**
 * Manage some state in an SSR-safe way. This uses Pinia under the hood, which
 * isolates state between SSR requests and propagates state from server to
 * client.
 *
 * @param id unique id within the application
 * @param init callback to generate initial state if needed
 * @returns reactive, writeable refs to the state's properties
 */
export function useState<T extends object>(id: string, init: () => T) {
  const definition = defineStore(id, { state: init });
  const store = definition();
  return storeToRefs(store);
}
