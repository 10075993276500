<!-- eslint-disable import/order -->
<script setup lang="ts">
import { ref } from 'vue';

import Modal from '@/components/base/layout/Modal.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import Header4 from '@/components/base/typography/Header4.vue';

withDefaults(
  defineProps<{
    ariaLabel: string;
    buttonText: string;
    buttonTheme?: 'green' | 'gray' | 'red' | 'white';
    modalHeader?: string;
  }>(),
  {
    buttonTheme: 'gray',
  },
);

const isModalOpen = ref(false);
</script>

<script lang="ts">
// eslint-disable-next-line import/first
import { BuilderComponent } from '@/utils/cms';

export const ButtonOpeningModalRegistration: BuilderComponent = {
  name: 'Button Opening Modal',
  canHaveChildren: true,
  inputs: [
    {
      name: 'ariaLabel',
      helperText: 'Short description of the modal purpose (1-3 words)',
      type: 'string',
      required: true,
    },
    {
      name: 'buttonText',
      helperText: 'Text to display on the button',
      type: 'string',
      required: true,
    },
    {
      name: 'buttonTheme',
      defaultValue: 'gray',
      enum: ['green', 'gray', 'red', 'white'],
      type: 'string',
    },
    {
      name: 'modalHeader',
      helperText: 'Header text for the modal',
      type: 'string',
    },
  ],
};
</script>

<template>
  <div>
    <ThemedButton @click="isModalOpen = true" data-test="button-opening-modal" :theme="buttonTheme">
      {{ buttonText }}
    </ThemedButton>
    <Modal
      :aria-label="ariaLabel"
      :hasControls="false"
      :isOpen="isModalOpen"
      width="md:w-auto"
      @handle-close="isModalOpen = false"
    >
      <template v-slot:header>
        <Header4 headerTag="span" class="text-left">{{ modalHeader }}</Header4>
      </template>
      <template v-slot:body>
        <slot />
      </template>
    </Modal>
  </div>
</template>
