import { dollars, from } from '@nuts/auto-delivery-sdk/dist/utils/money';
import pick from 'lodash/pick';
import type { CamelCasedProperties } from 'type-fest';

import { ProductAdded, productAdded } from '@/rudder-typer';
import analytics, { gtag } from '@/utils/analytics';
import { PageSection } from '@/utils/analytics/PageSection';
import { Money } from '@/utils/money';
import { ProductCardData } from '@/utils/productCard';
import { reportError } from '@/utils/reportError';

export interface NutsAddToCartItem
  extends Omit<CamelCasedProperties<ProductAdded>, 'pageSection' | 'price'> {
  readonly autodeliveryInterval?: number;
  readonly cost?: Money;
  readonly discount?: Money;
  /** Algolia index that served query results including this product */
  readonly indexName?: string;
  readonly itemListName?: string;
  readonly variant?: string;
  readonly markedAsGift?: boolean;
  readonly pageSection?: PageSection<
    | 'Added to Auto-Delivery Modal'
    | 'Added to Cart Modal'
    | 'Buy Block'
    | 'Product List'
    | 'Product Recommendations'
  >;
  readonly price: Money;
  readonly priceBeforeDiscount: Money;
  /** Algolia query identifier for Dynamic Re-ranking conversion events */
  readonly searchQueryID?: string;
  readonly weight?: number;
}

interface GtmProduct {
  id: string;
  itemExternalId: string;
  name: string;
  price: number | string;
  quantity: number;
}

export interface GtmAddToCartPayload {
  ecommerce: {
    add: {
      actionField: {
        list: string;
      };
      products: GtmProduct[];
    };
    currencyCode: string;
  };
}

function formatGtmAddToCartPayload(eventProps: NutsAddToCartItem): GtmAddToCartPayload {
  const quantity = eventProps.quantity || 1;
  const payload: GtmAddToCartPayload = {
    ecommerce: {
      add: {
        actionField: {
          list: eventProps.itemListName ?? '',
        },
        products: [
          {
            id: `0${eventProps.sku}`,
            itemExternalId: eventProps.productId ?? '',
            name: eventProps.name ?? '',
            price: String(dollars(Money.multiply(eventProps.price, quantity))),
            quantity,
          },
        ],
      },
      currencyCode: 'USD',
    },
  };

  return payload;
}

function formatGTagAddToCartPayload(eventProps: NutsAddToCartItem) {
  const quantity = eventProps.quantity || 1;
  const gtagEvent: Record<string, unknown> = {
    currency: 'USD',
    items: [
      {
        autodelivery_interval: eventProps.autodeliveryInterval,
        cost: eventProps.cost ? dollars(eventProps.cost) : undefined,
        coupon: eventProps.coupon,
        discount: eventProps.discount ? dollars(eventProps.discount) : undefined,
        index: eventProps.position,
        indexName: eventProps.indexName,
        item_id: eventProps.productId,
        item_list_name: eventProps.itemListName,
        item_name: eventProps.name,
        item_variant_name: eventProps.variant,
        item_variant: eventProps.sku,
        marked_as_gift: eventProps.markedAsGift,
        price_before_discount: dollars(eventProps.priceBeforeDiscount),
        price: dollars(eventProps.price),
        quantity: eventProps.quantity,
        reporting_category: eventProps.reportingCategory,
        searchQueryID: eventProps.searchQueryID,
        weight: eventProps.weight,
      },
    ],
    value: dollars(Money.multiply(eventProps.price, quantity)),
  };
  return gtagEvent;
}

function pickProductAdded(nutsAddToCartItem: NutsAddToCartItem): ProductAdded {
  const properties = pick(nutsAddToCartItem, [
    'coupon',
    'name',
    'position',
    'price',
    'quantity',
    'sku',
    'variant',
  ]);
  return {
    ...properties,
    cart_id: nutsAddToCartItem.cartId,
    page_section: nutsAddToCartItem.pageSection,
    price: dollars(nutsAddToCartItem.price),
    product_id: nutsAddToCartItem.productId,
    reporting_category: nutsAddToCartItem.reportingCategory,
  };
}

export function formatNutsAddToCartItemFromProductCard(
  productCard: ProductCardData,
  options: {
    cartId?: string;
    indexName?: string;
    itemListName?: string;
    markedAsGift?: boolean;
    pageSection: NutsAddToCartItem['pageSection'];
    position?: number;
    quantity: number;
  },
): NutsAddToCartItem {
  return {
    cost: Money.fromString(productCard.cost),
    coupon: productCard.totalSavings?.description?.en,
    discount: productCard.totalSavings?.value ?? from(0),
    name: productCard.name,
    price: productCard.piecePrice,
    priceBeforeDiscount: productCard.listPrice.value,
    productId: productCard.productKey,
    reportingCategory: productCard.reportingCategory,
    searchQueryID: productCard.searchQueryId,
    sku: productCard.sku,
    variant: productCard.unitName,
    weight: productCard.weight ? Number(productCard.weight) : 0,
    ...options,
  };
}

export function sendProductAddedEvent(nutsAddToCartItem: NutsAddToCartItem) {
  const gtmEventObj = formatGtmAddToCartPayload(nutsAddToCartItem);
  analytics.sendEvent('addToCart', '', gtmEventObj);

  const googleEventObj = formatGTagAddToCartPayload(nutsAddToCartItem);
  gtag('event', 'add_to_cart', googleEventObj);

  try {
    const productAddedPayload = pickProductAdded(nutsAddToCartItem);
    productAdded(productAddedPayload);
  } catch (e) {
    reportError(e);
  }
}
