import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';

import { ProductClicked, productClicked } from '@/rudder-typer';
import { PageSection } from '@/utils/analytics/PageSection';
import { ProductCardData } from '@/utils/productCard';

export type ProductClickedPageSection = PageSection<
  | 'Above-the-Fold Recommendations'
  | 'Added to Cart Modal'
  | 'Buy Block'
  | 'Product List'
  | 'Product Recommendations'
>;

export const formatProductClicked = (
  productCard: ProductCardData,
  pageSection?: ProductClickedPageSection,
  position?: number,
): ProductClicked => ({
  coupon: productCard.totalSavings?.description?.en,
  name: productCard.name,
  page_section: pageSection,
  position,
  price: dollars(productCard.piecePrice),
  product_id: productCard.productKey,
  quantity: 1,
  reporting_category: productCard.reportingCategory,
  sku: productCard.sku,
  variant: productCard.unitName,
});

export function isProductClickedPageSection(
  pageSection?: string,
): pageSection is ProductClickedPageSection {
  if (!pageSection) return true;
  return [
    'Above-the-Fold Recommendations',
    'Added to Cart Modal',
    'Buy Block',
    'Product List',
    'Product Recommendations',
  ].includes(pageSection);
}

export const sendProductClickedEvent = (
  productCard: ProductCardData,
  pageSection?: ProductClickedPageSection,
  position?: number,
): void => {
  try {
    const payload = formatProductClicked(productCard, pageSection, position);
    productClicked(payload);
  } catch (error) {
    reportError(error);
  }
};
