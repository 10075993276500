import { Cart, Order } from '@commercetools/platform-sdk';

import { NutsJson, webstore } from '@/api';
import { getConfigEntry } from '@/api/config';
import { NutsAddress } from '@/utils/address';

export interface PlaceOrderRequest {
  readonly cartId: string;
  readonly version: number;
  readonly billingAddress?: NutsAddress;
  /** From braintree.dataCollector; don't collect/send if isAdmin */
  readonly deviceData?: string;
  readonly 'g-recaptcha-response'?: string;
  readonly isB2b?: boolean;
  readonly newPassword?: string;
  readonly orderTags?: string[];
  readonly paymentNonces: string[];
  readonly placedByEmployee?: string;
  readonly purchaseOrderNumber?: string;
  readonly reshippedFromOrderNumber?: number;
  readonly safcToken?: string;
  readonly saveToWallet?: boolean;
  readonly setAsDefaultInWallet?: boolean;
  readonly signedLoginType?: string;
  /** Collect/send if isAdmin */
  readonly triggerSetPasswordFlow?: boolean;
  /** Temporarily rely on lambda to set customerId */
  readonly customerId?: string | null;
}

export interface PlaceOrderResponse {
  readonly order: Order;
  readonly receiptToken: string;
}

/** If there is an error, include the latest Cart to aid Webfront */
export interface PlaceOrderFailureResponse {
  readonly cart: Cart;
}

export async function placeOrder(request: PlaceOrderRequest) {
  const { placeOrderUrl } = getConfigEntry('api');
  const { data } = await webstore.sessionSpecific.post<
    NutsJson<PlaceOrderResponse | PlaceOrderFailureResponse>
  >(placeOrderUrl, request);
  return data;
}
