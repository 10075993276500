<script lang="ts">
import { email as validEmail, min, regex, required } from '@vee-validate/rules';
import axios from 'axios';
import dayjs from 'dayjs';
import { Field, useForm } from 'vee-validate';
import { computed, defineComponent, ref } from 'vue';

import { kustomerCorporateInquiry, KustomerCorporateInquiryFormat } from '@/api/kustomer';
import FieldErrorIcon from '@/components/base/assets/FieldErrorIcon.vue';
import CheckboxWithImage from '@/components/base/form/CheckboxWithImage.vue';
import FloatingLabel from '@/components/base/form/FloatingLabel.vue';
import InlineError from '@/components/base/form/InlineError.vue';
import Input from '@/components/base/form/Input.vue';
import Select from '@/components/base/form/OutlinedSelect.vue';
import Radio from '@/components/base/form/Radio.vue';
import Modal from '@/components/base/layout/Modal.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { FlexibleMessageValidator, useForm as formUtils } from '@/composables/useForm';
import { CmsRegisteredComponent } from '@/utils/cms';
import { DateString } from '@/utils/dateTime';

interface InquiryData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  company?: string;
  budget?: string;
  numberOfGifts?: string;
  greetingCard?: string;
  interests?: string[];
}

interface InquiryValidations {
  firstName: FlexibleMessageValidator<string>;
  lastName: FlexibleMessageValidator<string>;
  email: FlexibleMessageValidator<string>;
  phone: FlexibleMessageValidator<string>;
  company: FlexibleMessageValidator<string>;
}

const CorporateGiftsInquiryForm = defineComponent({
  name: 'CorporateGiftsInquiryForm',
  props: {
    buttonText: { required: false, type: String, default: 'Get Started' },
    buttonTheme: { required: false, type: String, default: 'gray' },
  },
  components: {
    CheckboxWithImage,
    Field,
    FieldErrorIcon,
    FloatingLabel,
    InlineError,
    // eslint-disable-next-line vue/no-reserved-component-names
    Input,
    Modal,
    Radio,
    // eslint-disable-next-line vue/no-reserved-component-names
    Select,
    ThemedButton,
  },
  setup() {
    const { handleSubmit, meta, resetForm } = useForm();
    const { errorMessages, validatorRegex, validatorFailed } = formUtils();

    const curated = ref(false);

    const initialFormData: InquiryData = {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phone: undefined,
      company: undefined,
      budget: undefined,
      numberOfGifts: undefined,
      greetingCard: undefined,
      interests: [],
    };

    const formData = ref<InquiryData>({ ...initialFormData });

    const isFormOpen = ref(false);
    const formSubmitted = ref(false);
    const showError = ref(false);

    const resetFormData = () => {
      formData.value = { ...initialFormData };
    };

    const handleOpenForm = () => {
      isFormOpen.value = true;
    };

    const handleCloseForm = () => {
      isFormOpen.value = false;
      formSubmitted.value = false;
      resetFormData();
    };

    const handleSelectInterest = (interest: string) => {
      if (formData.value?.interests) {
        const index = formData.value.interests.indexOf(interest);
        if (index && index < 0) {
          formData.value.interests.push(interest);
        } else {
          formData.value.interests.splice(index, 1);
        }
      }
    };

    const handleSubmitInquiryForm = handleSubmit(async () => {
      const { dataLayer } = window;
      const { originalLocation } = dataLayer.findLast((x: any) => x.originalLocation);

      const today = dayjs().format('YYYY-MM-DD') as DateString;
      const data = {
        ...formData.value,
        date: today,
        interests: formData.value.interests?.join(', ') ?? '',
        originalLocation,
      };

      const kustomerPayload: KustomerCorporateInquiryFormat = {
        customerName: `${data.firstName} ${data.lastName}`.trim(),
        companyName: data.company,
        customerEmail: data.email ?? '',
        customerPhoneNumber: data.phone ?? '',
        numberOfGifts: data.numberOfGifts ?? '',
        budget: data.budget ?? '',
        deliveryDate: '',
        additionalDetails: data.interests,
        customGreetingCard: data.greetingCard === 'yes',
        customSticker: false,
      };
      await kustomerCorporateInquiry(kustomerPayload);

      const dataForRequest = new URLSearchParams(data).toString();

      try {
        await axios.post(`https://hooks.zapier.com/hooks/catch/9407461/b08bw7c/?${dataForRequest}`);
      } catch (err) {
        showError.value = true;
      } finally {
        formSubmitted.value = true;

        window.dataLayer.push({
          event: 'corporate_lead',
        });
      }

      resetFormData();
      resetForm();
    });

    const budgetRange: readonly {
      readonly label: string;
      readonly value: string;
    }[] = [
      { label: 'Under $40', value: 'Under $40' },
      { label: '$40-70', value: '$40-70' },
      { label: '$70+', value: '$70+' },
    ];

    const schema = computed(() => {
      const rules: InquiryValidations = {
        firstName: (value) => required(value) || errorMessages.genericRequire.required,
        lastName: (value) => required(value) || errorMessages.genericRequire.required,
        email: (value) => {
          if (!required(value)) return errorMessages.email.required;
          if (!validEmail(value)) return errorMessages.email.email;
          return true;
        },
        phone: (value) => {
          if (!required(value)) return errorMessages.phone.required;
          if (!regex(value, [validatorRegex.phone])) return errorMessages.phone.regex;
          if (!min(value, [10])) return errorMessages.phone.min.replace('{length}', '10');
          return true;
        },
        company: (value) => required(value) || errorMessages.company.required,
      };

      return rules;
    });

    return {
      basketImg: nutshell['static_img/corporate-gifts/gift_baskets.jpg'],
      boxImg: nutshell['static_img/corporate-gifts/gift_box.jpg'],
      budgetRange,
      checkIcon: nutshell['static_img/corporate-gifts/green_check.svg'],
      curated,
      customImg: nutshell['static_img/corporate-gifts/custom_tray.jpg'],
      formData,
      formSubmitted,
      handleCloseForm,
      handleOpenForm,
      handleSelectInterest,
      handleSubmitInquiryForm,
      isFormOpen,
      meta,
      schema,
      surpriseImg: nutshell['static_img/corporate-gifts/surprise_me.jpg'],
      step1Img: nutshell['static_img/corporate-gifts/curated_list.png'],
      step2Img: nutshell['static_img/corporate-gifts/concierge_step_3.png'],
      step3Img: nutshell['static_img/corporate-gifts/concierge_step_4.png'],
      tinImg: nutshell['static_img/corporate-gifts/gift_tin.jpg'],
      trayImg: nutshell['static_img/corporate-gifts/gift_tray.jpg'],
      validatorFailed,
    };
  },
});

export const CorporateGiftsInquiryFormRegistration: CmsRegisteredComponent = {
  component: CorporateGiftsInquiryForm,
  name: 'Corporate Gifts Inquiry Form',
  inputs: [
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Get Started',
    },
    {
      name: 'buttonTheme',
      type: 'string',
      defaultValue: 'gray',
      enum: ['green', 'gray', 'red', 'white', 'yellow'],
    },
  ],
};
export default CorporateGiftsInquiryForm;
</script>

<template>
  <div>
    <ThemedButton @click="handleOpenForm()" :theme="buttonTheme">{{ buttonText }}</ThemedButton>
    <Teleport v-if="isFormOpen" to="#teleported">
      <Modal
        data-test="corporate-gifts-inquiry-form-modal"
        :isOpen="isFormOpen"
        :hasControls="false"
        width="md:max-w-3xl"
        class="root"
        @handle-close="handleCloseForm()"
      >
        <template v-slot:header>
          <h3 class="mb-8 text-left">Contact Us</h3>
        </template>
        <template v-slot:body>
          <template v-if="!formSubmitted">
            <div class="text-base font-semibold">1. How can we contact you?</div>
            <div class="flex gap-4 mt-2 mb-6">
              <Field
                v-model="formData.firstName"
                name="First Name"
                :rules="schema.firstName"
                v-slot="{ errors, field, meta }"
              >
                <div class="relative w-full md:w-1/2">
                  <Input
                    v-bind="field"
                    type="text"
                    placeholder="firstName"
                    aria-label="firstName"
                    autocomplete="given-name"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-first-name"
                  />
                  <FloatingLabel
                    class="left-0"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-first-name-label"
                  >
                    First Name*
                  </FloatingLabel>
                  <FieldErrorIcon :showErrorIcon="validatorFailed(meta)" />
                  <InlineError
                    :errors="errors"
                    fieldName="firstName"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-first-name-validation-error"
                    @input-failed="onInputFailed"
                  />
                </div>
              </Field>
              <Field
                v-model="formData.lastName"
                name="Last Name"
                :rules="schema.lastName"
                v-slot="{ errors, field, meta }"
              >
                <div class="relative w-full md:w-1/2">
                  <Input
                    v-bind="field"
                    type="text"
                    placeholder="lastName"
                    aria-label="lastName"
                    autocomplete="family-name"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-last-name"
                  />
                  <FloatingLabel
                    class="left-0"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-last-name-label"
                  >
                    Last Name*
                  </FloatingLabel>
                  <FieldErrorIcon :showErrorIcon="validatorFailed(meta)" />
                  <InlineError
                    :errors="errors"
                    fieldName="lastName"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-last-name-validation-error"
                    @input-failed="onInputFailed"
                  />
                </div>
              </Field>
            </div>
            <div class="flex gap-4 my-6">
              <Field
                v-model="formData.email"
                name="Work Email"
                :rules="schema.email"
                v-slot="{ errors, field, meta }"
              >
                <div class="relative w-full md:w-1/2">
                  <Input
                    v-bind="field"
                    type="text"
                    placeholder="email"
                    aria-label="email"
                    autocomplete="email"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-work-email"
                  />
                  <FloatingLabel
                    class="left-0"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-work-email-label"
                  >
                    Work Email*
                  </FloatingLabel>
                  <FieldErrorIcon :showErrorIcon="validatorFailed(meta)" />
                  <InlineError
                    :errors="errors"
                    fieldName="email"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-work-email-validation-error"
                    @input-failed="onInputFailed"
                  />
                </div>
              </Field>
              <Field
                v-model="formData.phone"
                name="Phone Number"
                :rules="schema.phone"
                v-slot="{ errors, field, meta }"
              >
                <div class="relative w-full md:w-1/2">
                  <Input
                    v-bind="field"
                    type="text"
                    placeholder="phone"
                    aria-label="phone"
                    autocomplete="phone"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-work-phone"
                  />
                  <FloatingLabel
                    class="left-0"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-work-phone-label"
                  >
                    Phone Number*
                  </FloatingLabel>
                  <FieldErrorIcon :showErrorIcon="validatorFailed(meta)" />
                  <InlineError
                    :errors="errors"
                    fieldName="phone"
                    :invalid="validatorFailed(meta)"
                    data-test="inquiry-work-phone-validation-error"
                    @input-failed="onInputFailed"
                  />
                </div>
              </Field>
            </div>
            <Field
              v-model="formData.company"
              name="Company Name"
              :rules="schema.company"
              v-slot="{ errors, field, meta }"
            >
              <div class="relative w-full">
                <Input
                  v-bind="field"
                  type="text"
                  placeholder="company"
                  aria-label="company"
                  :invalid="validatorFailed(meta)"
                  data-test="inquiry-company"
                />
                <FloatingLabel
                  class="left-0"
                  :invalid="validatorFailed(meta)"
                  data-test="inquiry-company-label"
                >
                  Company Name*
                </FloatingLabel>
                <FieldErrorIcon :showErrorIcon="validatorFailed(meta)" />
                <InlineError
                  :errors="errors"
                  fieldName="company"
                  :invalid="validatorFailed(meta)"
                  data-test="inquiry-company-validation-error"
                  @input-failed="onInputFailed"
                />
              </div>
            </Field>
            <div class="mt-6 md:mt-8">
              <div class="text-base font-semibold">2. What's your budget?</div>
              <Select
                v-model="formData.budget"
                name="budget"
                class="mt-2"
                :options="budgetRange"
                placeholder="budget"
                aria-label="budget"
                data-test="address-budget"
              />
            </div>
            <div class="mt-6 md:mt-8">
              <div class="text-base font-semibold">3. How many people are you gifting to?</div>
              <Input
                v-model="formData.numberOfGifts"
                name="numberOfGifts"
                class="mt-2"
                type="number"
                min="1"
                placeholder="15"
                aria-label="number-gifts"
                data-test="inquiry-number-gifts"
              />
            </div>
            <div class="mt-6 md:mt-8">
              <div class="text-base font-semibold">
                4. Are you interested in a greeting card ($3 each) with a personalized message?
              </div>
              <div
                aria-label="Greeting Card Options"
                data-test="greeting-card-options"
                role="radiogroup"
                class="flex gap-8 mt-2"
              >
                <Radio
                  v-model="formData.greetingCard"
                  aria-label="Greeting Card selector"
                  data-test="greeting-card-selector"
                  name="greeting-card-type"
                  role="radio"
                  value="no"
                >
                  <div
                    class="flex items-center justify-between w-full text-base font-normal cursor-pointer"
                  >
                    No
                  </div>
                </Radio>
                <Radio
                  v-model="formData.greetingCard"
                  aria-label="Greeting Card selector"
                  data-test="greeting-card-selector"
                  name="greeting-card-type"
                  role="radio"
                  value="yes"
                >
                  <div
                    class="flex items-center justify-between w-full text-base font-normal cursor-pointer"
                  >
                    Yes
                  </div>
                </Radio>
              </div>
            </div>
            <div class="mt-6 md:mt-8">
              <div class="text-base font-semibold">
                5. What type of product interests you? Select all that apply.
              </div>
              <div class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2">
                <CheckboxWithImage
                  label="Custom Gifts"
                  :imageSrc="customImg"
                  @change="handleSelectInterest"
                />
                <CheckboxWithImage
                  label="Gift Trays"
                  :imageSrc="trayImg"
                  @change="handleSelectInterest"
                />
                <CheckboxWithImage
                  label="Gift Boxes"
                  :imageSrc="boxImg"
                  @change="handleSelectInterest"
                />
                <CheckboxWithImage
                  label="Gift Tins"
                  :imageSrc="tinImg"
                  @change="handleSelectInterest"
                />
                <CheckboxWithImage
                  label="Gift Baskets"
                  :imageSrc="basketImg"
                  @change="handleSelectInterest"
                />
                <CheckboxWithImage
                  label="Surprise Me"
                  :imageSrc="surpriseImg"
                  @change="handleSelectInterest"
                />
              </div>
            </div>
            <ThemedButton
              theme="gray"
              class="mt-6 ml-auto"
              :disabled="!meta.touched || validatorFailed(meta)"
              @click="handleSubmitInquiryForm()"
            >
              Submit
            </ThemedButton>
          </template>
          <template v-else>
            <div class="flex">
              <img :src="checkIcon" alt="check" />
              <div class="ml-4 text-lg font-semibold">
                Thank you for submitting your request! Here is what happens next:
              </div>
            </div>
            <div class="grid grid-cols-2 gap-4 mt-6 lg:grid-cols-3">
              <div class="flex flex-col items-center">
                <img :src="step1Img" class="w-full rounded-xl" />
                <div class="mt-4 text-base font-semibold text-center lg:px-4">
                  We'll send you a curated gift list and address form shortly
                </div>
              </div>
              <div class="flex flex-col items-center">
                <img :src="step2Img" class="w-full rounded-xl" />
                <div class="mt-4 text-base font-semibold text-center lg:px-4">
                  We'll help you select your gifts and fill out the address form
                </div>
              </div>
              <div class="flex flex-col items-center">
                <img :src="step3Img" class="w-full rounded-xl" />
                <div class="mt-4 text-base font-semibold text-center lg:px-4">
                  We'll carefully prepare and ship your order &#8212; fast and fresh!
                </div>
              </div>
            </div>
            <ThemedButton theme="white" class="mt-6 ml-auto" @click="handleCloseForm()">
              Ok
            </ThemedButton>
          </template>
        </template>
      </Modal>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.root {
  /* Overlap kustomer FAB */
  z-index: 2147483001;
}
</style>
