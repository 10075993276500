/* eslint-disable camelcase */
import { fromAxiosNutsJson, NutsJson, webstore } from '@/api';

interface CreateContactRequest {
  readonly email: string;
  readonly b2b?: boolean;
  readonly b2bSource?: 'cx checkout buying for business' | 'checkout buying for business';
  readonly reason?: string;
  readonly subscribed?: true | false | 'unchecked' | 'if-new';
  readonly jobTitle?: string;
}

export async function createContact(request: CreateContactRequest) {
  await fromAxiosNutsJson(
    webstore.sessionSpecific.post<NutsJson<void>>('/api/contacts', request),
    {},
  );
}

export async function subscribe(email: string, firstName = '') {
  const formData = new FormData();
  formData.set('inputEmail', email);
  formData.set('inputFirstName', firstName);
  formData.set('detailsForm', '1');
  formData.set('reason', 'footer');
  const response = await fromAxiosNutsJson<NutsJson>(webstore.post('/subscribe', formData), {});

  return response;
}
