<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { watchEffect } from 'vue';

import { useDialog } from '@/stores/dialog';

interface Props {
  centeredOnViewport?: boolean;
  show: boolean;
}

const props = withDefaults(defineProps<Props>(), { centeredOnViewport: true });
const emit = defineEmits(['hide']);

const isMobile = useBreakpoints(breakpointsTailwind).smaller('md');

watchEffect(() => {
  if (props.show) {
    if (isMobile.value && useDialog().activeModalCount === 0) {
      window.Kustomer?.stop();
    }
    setTimeout(() => {
      emit('hide');
      if (isMobile.value && useDialog().activeModalCount === 0) {
        window.Kustomer?.start();
      }
    }, 3000);
  }
});
</script>

<template>
  <transition name="slide">
    <div
      v-if="show"
      class="fixed z-[100] flex items-center px-4 sm:py-4 py-3 text-center text-white bg-black rounded"
      data-test="toast-notification"
      :class="[isMobile ? 'bottom' : 'top', { 'centered-on-viewport': centeredOnViewport }]"
    >
      <slot />
    </div>
  </transition>
</template>

<style scoped>
.centered-on-viewport.top {
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
}

.bottom {
  bottom: 8px;
  left: 8px;
  right: 8px;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}

.slide-enter-from {
  transform: translateY(-150%);
}

.slide-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.slide-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.slide-leave-to {
  opacity: 0;
}

.centered-on-viewport.slide-enter-from.top {
  transform: translateX(-50%) translateY(-130%);
}

.centered-on-viewport.slide-enter-to.top {
  transform: translateX(-50%) translateY(0);
}

.slide-enter-from.bottom {
  transform: translateY(115%);
}

.slide-enter-to.bottom {
  transform: translateY(0);
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.75s ease-in-out, opacity 0.75s ease-in-out;
  transition-delay: 0.5s;
}
</style>
