<script setup lang="ts">
import { useEventListener } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import CloseModalButton from '@/components/base/layout/modal/CloseModalButton.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import { useDialog } from '@/stores/dialog';

const props = withDefaults(
  defineProps<{
    anchor?: 'center' | 'right';
    backgroundColor?: string;
    breakpoint?: 'sm' | 'md';
    disableConfirmButton?: boolean;
    hasControls?: boolean;
    hasDefaultPadding?: boolean;
    isLoading?: boolean;
    isOpen: boolean;
    skipFirstElementOnFocus?: boolean;
    width?: string;
  }>(),
  {
    anchor: 'center',
    backgroundColor: 'bg-white',
    breakpoint: 'md',
    disableConfirmButton: false,
    hasControls: true,
    hasDefaultPadding: true,
    isLoading: false,
    isOpen: false,
  },
);

const emit = defineEmits(['handle-cancel', 'handle-close', 'handle-confirm']);

const handleCancel = () => emit('handle-cancel');
const handleClose = () => emit('handle-close');
const handleConfirm = () => emit('handle-confirm');

const modalRoot = ref<HTMLDivElement>();

const dialogStore = useDialog();
const { activate, deactivate } = useFocusTrap(modalRoot, {
  immediate: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  preventScroll: true,
});

const onModalShown = () => {
  dialogStore.activeModalCount += 1;
  window.Kustomer?.stop();
  nextTick().then(() => activate());
};

const onModalHidden = () => {
  dialogStore.activeModalCount = Math.max(0, dialogStore.activeModalCount - 1);
  deactivate();
  if (dialogStore.activeModalCount === 0) {
    window.Kustomer?.start();
  }
};

onMounted(() => {
  if (props.isOpen) onModalShown();

  useEventListener('keydown', (e: KeyboardEvent) => {
    if (!props.isOpen) return;
    if (e.key === 'Escape') {
      handleClose();
      e.preventDefault();
    }
  });
});

onBeforeUnmount(onModalHidden);

watch(
  () => props.isOpen,
  (val) => {
    if (val) onModalShown();
    else {
      onModalHidden();
    }
  },
);

defineOptions({ inheritAttrs: false });

const classes = {
  anchor: {
    center: {
      md: 'md:items-center md:justify-center',
      sm: 'sm:items-center sm:justify-center',
    },
    right: {
      md: 'md:items-stretch md:justify-end',
      sm: 'sm:items-stretch sm:justify-end',
    },
  },
  radius: {
    center: {
      md: 'md:rounded-xl',
      sm: 'sm:rounded-xl',
    },
    right: {
      md: 'md:rounded-none',
      sm: 'sm:rounded-none',
    },
  },
};
</script>

<template>
  <Transition name="modal-visible">
    <div
      v-if="isOpen"
      aria-modal="true"
      class="fixed inset-0 z-50 overflow-y-auto scrollbar-hide overscroll-none"
      ref="modalRoot"
      role="dialog"
      v-bind="$attrs"
    >
      <!-- Overlay -->
      <div
        class="modal-overlay absolute inset-0 z-0 bg-black opacity-40 touch-none h-[calc(100%+0.5px)]"
        @click="handleClose"
      />
      <!-- Modal container -->
      <div class="absolute inset-0 flex overflow-hidden opacity-100 pointer-events-none z-1">
        <div
          class="flex items-end justify-end flex-1 overflow-hidden"
          :class="classes.anchor[anchor][breakpoint]"
        >
          <div
            class="max-h-[80vh] w-full rounded-t-xl overflow-y-auto border-solid shadow-lg pointer-events-auto modal-container isolate overscroll-contain touch-pan-y touch-pan-x"
            :class="[
              anchor,
              breakpoint,
              backgroundColor,
              width,
              classes.radius[anchor][breakpoint],
              { [breakpoint === 'md' ? 'md:max-h-screen' : 'sm:max-h-screen']: anchor === 'right' },
            ]"
          >
            <div class="sticky top-0 right-0 z-50 flex justify-end text-right">
              <slot name="close-button">
                <CloseModalButton class="absolute top-4 right-4" @click="handleClose" />
              </slot>
            </div>
            <div
              :class="[
                {
                  'px-4 py-6': hasDefaultPadding,
                  [breakpoint === 'sm' ? 'sm:px-6' : 'md:px-6']: hasDefaultPadding,
                },
              ]"
            >
              <div :class="{ 'mt-4': hasDefaultPadding }">
                <slot name="header" />
              </div>

              <div class="modal-body">
                <slot name="body" />
              </div>

              <slot name="modal-controls">
                <div
                  v-if="hasControls"
                  class="flex justify-between mt-6"
                  :class="breakpoint === 'sm' ? 'sm:mt-8' : 'md:mt-8'"
                >
                  <slot name="cancel-button">
                    <ThemedButton
                      class="mr-4"
                      :class="breakpoint === 'sm' ? 'sm:w-40' : 'md:w-40'"
                      theme="white"
                      type="button"
                      @click="handleCancel"
                      data-test="cancel"
                    >
                      <slot name="cancel-label">Cancel</slot>
                    </ThemedButton>
                  </slot>

                  <slot name="confirm-button">
                    <ThemedButton
                      :class="breakpoint === 'sm' ? 'sm:w-40' : 'md:w-40'"
                      :disabled="disableConfirmButton"
                      :isLoading="isLoading"
                      theme="gray"
                      type="button"
                      @click="handleConfirm"
                      data-test="confirm"
                    >
                      <slot name="confirm-label">Save</slot>
                    </ThemedButton>
                  </slot>
                </div>
              </slot>
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.modal-visible-enter-active,
.modal-visible-leave-active {
  @apply duration-300;

  .modal-overlay {
    transition: opacity 0.2s ease;
  }

  .modal-container {
    @apply transition-all duration-300 ease-out;

    &.center {
      @apply md:duration-200;
    }
  }
}

.modal-visible-enter-from,
.modal-visible-leave-to {
  .modal-overlay {
    opacity: 0;
  }

  .modal-container {
    &.md {
      @media screen and (max-width: $tailwind-md-max) {
        transform: translateY(100%);
      }
    }
    &.sm {
      @media screen and (max-width: $tailwind-sm-max) {
        transform: translateY(100%);
      }
    }

    &.center {
      @apply md:opacity-0;
    }

    &.right {
      &.md {
        @apply md:translate-x-full;
      }
      &.sm {
        @apply sm:translate-x-full;
      }
    }
  }
}
</style>
