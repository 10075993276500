<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { computed } from 'vue';

import { AlgoliaVariant } from '@/api/algolia';
import { reportEngagement } from '@/api/dynamic-yield/engagement';
import Slider from '@/components/base/Slider.vue';
import ProductCardWithOptions from '@/components/product-card/ProductCardWithOptions.vue';
import { Theme as ButtonTheme } from '@/composables/useButtonStyles';
import { SpacingDirection, SpacingLevel, useSpacing } from '@/composables/useSpacing';
import { BaseChoice, RecommendationsSlot } from '@/lib/personalization/dynamicYield';
import { WebstoreRecommendation } from '@/lib/personalization/webstore';
import { NutsAddToCartItem } from '@/utils/analytics/productAddedEvent';
import { ProductClickedPageSection } from '@/utils/analytics/productClickedEvent';
import { ProductListViewedEventData } from '@/utils/analytics/productListViewedEvent';
import { displayPrice, ProductCard, ProductCardData } from '@/utils/productCard';

dayjs.extend(relativeTime);

type Recommendation = AlgoliaVariant | RecommendationsSlot | WebstoreRecommendation;

interface Props {
  addedSkus?: Set<string>;
  analyticsList?: string;
  buttonTheme?: ButtonTheme;
  clickTrackable?: boolean;
  controlsColor?: string;
  decisionId?: BaseChoice['decisionId'];
  enableAddToCart?: boolean;
  flushRightOnMobile?: boolean;
  impressionTrackable?: boolean;
  layout?: string;
  link?: string;
  list?: Recommendation[];
  pageSection?:
    | ProductClickedPageSection
    | ProductListViewedEventData['pageSection']
    | NutsAddToCartItem['pageSection'];
  spacingDirection?: SpacingDirection;
  spacingLevel?: SpacingLevel;
  subtitle?: string;
  title?: string;
  trackingEventLocation?: string;
}
const props = withDefaults(defineProps<Props>(), {
  analyticsList: '',
  buttonTheme: 'green',
  clickTrackable: true,
  impressionTrackable: true,
  layout: 'row',
  trackingEventLocation: '',
});

const { spacing } = useSpacing(props);

const algoliaVariant = (recommendation: Recommendation): recommendation is AlgoliaVariant =>
  'objectID' in recommendation;
const webstoreRecommendation = (
  product: RecommendationsSlot | WebstoreRecommendation,
): product is WebstoreRecommendation => 'last_ordered_at' in product;
const recommendations = computed(() =>
  (props.list ?? []).map((recommendation) => {
    let productCard: ProductCardData;
    let lastOrderedAt: string | undefined;
    if (algoliaVariant(recommendation)) {
      productCard = ProductCard.fromAlgolia(recommendation);
    } else if (webstoreRecommendation(recommendation)) {
      productCard = ProductCard.fromWebstore(recommendation);
      lastOrderedAt = dayjs().to(dayjs(recommendation.last_ordered_at));
    } else {
      productCard = ProductCard.fromDY(recommendation);
    }
    return {
      displayPrice: displayPrice(productCard),
      productCard,
      purchasedAt: lastOrderedAt,
      recommendation,
    };
  }),
);

const emit = defineEmits<{
  (e: 'update:addedSkus', value: string): void;
  (e: 'click'): void;
}>();

const addedItems = useVModel(props, 'addedSkus', emit);
const isAddedToCart = (sku: string) => addedItems.value?.has(sku);

const handleAddToCartClick = async (product: ProductCardData) => {
  addedItems.value?.add(product.sku);
  if (props.decisionId && product.slotId) reportEngagement(props.decisionId, product.slotId);
};
</script>

<template>
  <div
    v-if="recommendations.length"
    class="flex items-end"
    :class="spacing"
    data-test="recommendations"
  >
    <Slider
      :controlsColor="controlsColor"
      :flushRightOnMobile="flushRightOnMobile"
      justifyContent="start"
      :layout="layout"
      :link="link"
      :list="recommendations"
      :responsiveGaps="{ mobile: 8, tablet: 12 }"
      :subtitle="subtitle"
      :title="title"
      useResponsiveGaps
    >
      <ProductCardWithOptions
        v-for="({ productCard }, idx) in recommendations"
        :analyticsList="analyticsList"
        :clickTrackable="clickTrackable"
        :decisionId="decisionId"
        :disabledAddToCartButton="isAddedToCart(productCard.sku)"
        :impressionTrackable="impressionTrackable"
        :key="productCard.productKey"
        layout="recommendations"
        :pageSection
        :position="idx"
        :product="productCard"
        :slotId="productCard.slotId"
        :trackingEventLocation="trackingEventLocation || title"
        :showVariants="false"
        @added="handleAddToCartClick(productCard)"
        @click="$emit('click')"
      >
        <template v-if="isAddedToCart(productCard.sku)" #add-to-cart-text>Added</template>
        <template v-if="$slots.button" #button>
          <slot name="button" :product="productCard" />
        </template>
      </ProductCardWithOptions>
    </Slider>
  </div>
</template>

<style lang="scss" scoped>
.slide-card {
  scroll-snap-align: start;
  min-width: 154px;
  max-width: 173px;
  border: 1px solid #e8e8e8;
}
.easy-reorder {
  display: none;
}
.circle {
  background: #6fcee4 url('https://nuts.com/images/pdp/2022/shop-bag.e53f4a9a.png') no-repeat
    center/50px 50px;
}

@media screen and (min-width: 1100px) {
  .slide-card {
    max-width: 198px;
    height: 348px;
  }
  .easy-reorder {
    display: flex;
  }
}
</style>
