<script setup lang="ts">
import { ProposedDelivery, Subscription } from '@nuts/auto-delivery-sdk';
import { computed } from 'vue';

import AutoDeliveryArrowsIcon from '@/components/base/assets/AutoDeliveryArrowsIcon.vue';
import TextButton from '@/components/base/TextButton.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Caption from '@/components/base/typography/Caption.vue';
import { getAutoDeliveryDetailsPath } from '@/utils/autoDelivery';

const props = defineProps<{
  delivery: ProposedDelivery;
  isLoading?: boolean;
  subscription: Subscription;
}>();
defineEmits<{ remove: [] }>();

const autoDeliveryFrequencyText = computed(() =>
  props.subscription.interval
    ? `${props.subscription.interval / 7} Week Frequency`
    : `Ships one time`,
);
</script>

<template>
  <div
    class="flex flex-col gap-2 p-3 rounded-lg shrink-0 bg-nuts-cyan-50"
    :class="{ 'opacity-50': isLoading }"
  >
    <div class="flex items-center gap-1 text-nuts-cyan-700">
      <AutoDeliveryArrowsIcon :class="{ 'animate-spin': isLoading }" :size="20" />
      <BaseBodyText class="font-semibold">
        {{ autoDeliveryFrequencyText }}
      </BaseBodyText>
    </div>
    <div class="flex gap-2 ml-6">
      <RouterLink
        class="hover:no-underline focus:no-underline"
        :class="{ 'pointer-events-none': isLoading }"
        data-test="manage-auto-delivery-link"
        :to="getAutoDeliveryDetailsPath(delivery)"
      >
        <Caption class="underline text-nuts-cyan-700 hover:font-semibold">Manage</Caption>
      </RouterLink>
      <TextButton
        class="flex"
        data-test="remove-auto-delivery-button"
        :disabled="isLoading"
        @click="$emit('remove')"
      >
        <Caption class="underline hover:font-semibold">Remove</Caption>
      </TextButton>
    </div>
  </div>
</template>
